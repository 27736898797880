exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-agile-transformation-culture-transformation-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/agile-transformation-culture-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-agile-transformation-culture-transformation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-focus-on-value-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/focus-on-value/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-focus-on-value-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-implementing-kanban-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/implementing-kanban/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-implementing-kanban-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-refactoring-cleaning-office-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/refactoring-cleaning-office/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-refactoring-cleaning-office-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-right-information-right-moment-right-place-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/right-information-right-moment-right-place/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-right-information-right-moment-right-place-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-seven-actions-better-effective-user-stories-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/seven-actions-better-effective-user-stories/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-seven-actions-better-effective-user-stories-index-mdx" */)
}

